
import App from './App.vue'
import { createApp } from 'vue'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { VueMaskDirective } from 'v-mask';
import { i18n } from './vue-zina-router';
import VueKinesis from "vue-kinesis";
import VueScrollTo from "vue-scrollto";
import VueEasyLightbox from 'vue-easy-lightbox'
import router from './router'
import store from './store';
import vClickOutside from "click-outside-vue3"
import components from "@/components/UI";
import directives from "@/directives";
import VueScreen from 'vue-screen';
import VueSplide from '@splidejs/vue-splide';
import './registerServiceWorker';
import './tailwind.css'
import 'animate.css'

const metaManager = createMetaManager();
const app = createApp(App);

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

directives.forEach(directive => {
    app.directive(directive.name, directive);
});

components.forEach(component => {
    app.component(component.name, component);
});

app
    .use(VueSplide)
    .use(router)
    .use(store)
    .use(metaManager, { refreshOnceOnNavigation: true })
    .use(metaPlugin)
    .use(i18n)
    .use(VueKinesis)
    .use(vClickOutside)
    .use(VueEasyLightbox)
    .directive('mask', vMaskV3)
    .use(VueScrollTo, {
        container: "body",
        duration: 500,
        easing: "ease",
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    })
    .use(VueScreen, 'tailwind')
    .mount('#app')
